<template>
  <!-- Home -->
  <b-container fluid>
    <b-row no-gutters style="min-height:75vh;">
      <b-col class="text-center my-auto">
        <h3 class="font-weight-bold mb-3">
          Welcome to {{ $store.state.vendor.name }}
        </h3>
        <router-link class="btn font-weight-bold mb-3" to="/menu">
          <template v-if="startOrder">Start Order</template>
          <template v-else>Resume Order</template>
        </router-link>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Home",
  computed: {
    startOrder: function() {
      return this._.isEmpty(this.$store.state.order.tickets);
    },
  },
};
</script>
